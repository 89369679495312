import React, { useEffect } from 'react'
import { useWizard } from 'react-use-wizard'
import Test from '../../../wikijob/src/components/Test'
import PaginationComp from './PaginationComp'
import decorateUrlTid from '../../utils/decorateUrlTid'
import './styles.scss'

function WizardChild({
  questionIndex,
  id,
  infoNode,
  questionNode,
  explanationNode,
  totalQuestions,
  answersJson,
  name,
  questions,
  handleNavigate,
  handleAnswer,
  url,
  showResults,
  resetTest,
  answersx,
  location,
  fromAptitudePage,
  setIndex,
  setanswers,
  setQuestionIndex,
  setShowResults,
  setResetTest,
}) {
  const { nextStep, goToStep } = useWizard()

  useEffect(() => {
    const countOfLabels = document
      .getElementsByClassName('questionContent')[0]
      .getElementsByTagName('label')
    for (let indx = 0; indx < countOfLabels?.length; indx += 1) {
      const hasAnswer = countOfLabels
        ?.item(indx)
        ?.classList?.contains('answeredPoint')
      if (hasAnswer) {
        countOfLabels?.item(indx).classList.remove('answeredPoint')
      }
    }
    const answeredPoint = countOfLabels?.[answersx?.[questionIndex]?.index]
    answeredPoint?.classList?.add('answeredPoint')
  }, [questionIndex, showResults])

  useEffect(() => {
    if (resetTest === true) {
      goToStep(0)
      setResetTest(false)
    }
  }, [resetTest])

  return (
    <div key={id}>
      <div
        {...{
          className: 'questionContent',
          key: id,
        }}
      >
        <div className="questionCount">
          {name}: question {questionIndex + 1} of {totalQuestions}
        </div>
        <Test
          {...{
            info: infoNode.childMarkdownRemark.html,
            question: questionNode.childMarkdownRemark.html,
            explanation: explanationNode.childMarkdownRemark.html,
            answersJson: JSON.parse(answersJson),
            selectedAnswer: showResults,
            handleAnswer: (answerIndex, name) => {
              nextStep()

              return handleAnswer(
                questionIndex,
                answerIndex,
                setIndex,
                setanswers,
                setQuestionIndex,
                answersx,
                name,
              )
            },
          }}
        />
        <div className="questionPagination">
          <PaginationComp
            {...{
              fromAptitudePage,
              answers: answersx,
              questions,
              handleNavigate: () => handleNavigate(setShowResults),
              lastQuestion: questionIndex === questions.length - 1,
              goToStep,
            }}
          />
        </div>
      </div>
      {fromAptitudePage && (
        <div className="practiceMore">
          <a
            data-name="practiceMoreBtn"
            {...{
              className: 'btn btn-orange btn-lg',
              rel: 'sponsored',
              href: decorateUrlTid(
                url,
                location.pathname,
                'practicemore',
                'wiki',
              ),
              target: '_blank',
            }}
          >
            CLICK HERE TO PRACTICE MORE TESTS
          </a>
        </div>
      )}
      <div className="row">
        <div className="content_hint" />
      </div>
    </div>
  )
}

export default WizardChild
