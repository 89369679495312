import React from 'react'
import CoverImage from '../CoverImage/CoverImage.component'
import style from './CategoryHeader.module.scss'

const CategoryHeader = ({ title, subTitle, coverImage }) => {
  return (
    <div
      className={style.wrapper}
      style={{
        backgroundImage: `url(${coverImage?.gatsbyImageData?.images?.fallback?.src})`,
      }}
    >
      <div className={style.textWrapper}>
        <h1 className="fw-bold">{title}</h1>
        <h2 className="w-50 text-center fw-bold">{subTitle}</h2>
      </div>
    </div>
  )
}

export default CategoryHeader
