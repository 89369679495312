import React, { useState, useEffect } from 'react'
import { window } from 'browser-monads'
import { Wizard } from 'react-use-wizard'
import Results from '../../../wikijob/src/components/Results'
import './styles.scss'
import {
  handleAnswer,
  handleNavigate,
  scrollToNextQuestion,
} from '../../utils/aptitudeHelpers'
import jobTestPrepImage from '../../images/job-test-prep.png'
import testHqImage from '../../images/testhq-logo.png'
import decorateUrlTid from '../../utils/decorateUrlTid'
import PracticeProblemSchema from '../PracticeProblemSchema'
import replaceText from '../../utils/replaceText'
import WizardChild from './WizardChild'

function AptitudetestCompo(props) {
  const [, setIndex] = useState(0)
  const [answersx, setanswers] = useState({})
  const [, setQuestionIndex] = useState(0)
  const [showResults, setShowResults] = useState(false)
  const [resetTest, setResetTest] = useState(false)
  const [, setInitialOffset] = useState(0)
  const {
    data: test,
    location,
    fromAptitudePage = undefined,
    article = undefined,
    tokens,
  } = props

  const handleReset = () => {
    setResetTest(true)
    setTimeout(() => {
      const allSelectedAnswers = document.querySelectorAll('.answeredPoint')

      allSelectedAnswers.forEach((element) => {
        element.classList.remove('answeredPoint')
      })
      setShowResults(false)
      setIndex(0)
      setQuestionIndex(0)
      setanswers({})
    }, 100)

    scrollToNextQuestion(-1)
  }

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'componentDidMount' })
    }
    setTimeout(setInitialOffset(window.pageYOffset), 300)
  }, [props, test.name, test.url])

  const getImageData = (url) => {
    if (url.includes('l.wikijob.co.uk')) {
      return {
        src: testHqImage,
        alt: 'Testhq',
        className: 'jobTestPrepImage',
      }
    }
    return {
      src: jobTestPrepImage,
      alt: 'JobTestPrep',
      className: 'jobTestPrepImage',
    }
  }

  return (
    <>
      <PracticeProblemSchema
        questions={test && test?.questions}
        title={replaceText(
          tokens,
          article?.metaTitle || article?.title || test.metaTitle,
        )}
        description={
          (article?.metaDescription &&
            replaceText(tokens, article?.metaDescription)) ||
          test.metaDescription
        }
      />
      {test.introText && (
        <div className="card" style={{ position: 'static' }}>
          <div className="card-body text-center">
            <div
              className={fromAptitudePage ? '' : 'heading_banner'}
              dangerouslySetInnerHTML={{ __html: test.introText }}
            />
            {fromAptitudePage && (
              <a
                {...{
                  href: decorateUrlTid(
                    test.url,
                    location.pathname,
                    'jtplogo',
                    'wiki',
                  ),
                  rel: 'sponsored',
                  className: 'jobTestPrepLink',
                  target: '_blank',
                }}
              >
                <span>Powered by</span>

                <img {...getImageData(test.url)} />
              </a>
            )}
          </div>
        </div>
      )}
      <div className="allQuestion">
        <div className="allQuestionList">
          <Wizard>
            {test.questions.map(
              (
                { infoNode, questionNode, explanationNode, answersJson, id },
                questionIndex,
              ) => {
                const totalQuestions = test.questions.length
                return (
                  <WizardChild
                    key={id}
                    url={test.url}
                    handleNavigate={handleNavigate}
                    questions={test.questions}
                    handleAnswer={handleAnswer}
                    name={test.name}
                    questionIndex={questionIndex}
                    id={id}
                    infoNode={infoNode}
                    questionNode={questionNode}
                    explanationNode={explanationNode}
                    totalQuestions={totalQuestions}
                    answersJson={answersJson}
                    showResults={showResults}
                    resetTest={resetTest}
                    answersx={answersx}
                    setShowResults={setShowResults}
                    setResetTest={setResetTest}
                    location={location}
                    setIndex={setIndex}
                    setanswers={setanswers}
                    setQuestionIndex={setQuestionIndex}
                    fromAptitudePage={fromAptitudePage}
                  />
                )
              },
            )}
          </Wizard>
          {showResults && (
            <>
              <Results
                {...{
                  questions: test.questions,
                  answers: answersx,
                  passPercentage: test.passPercentage,
                  handleReset,
                }}
              />
              {fromAptitudePage && (
                <div className="practiceMore">
                  <a
                    data-name="practiceMoreBtn"
                    {...{
                      className: 'btn btn-orange btn-lg',
                      rel: 'sponsored',
                      href: decorateUrlTid(
                        test.url,
                        location.pathname,
                        'practicemore',
                        'wiki',
                      ),
                      target: '_blank',
                    }}
                  >
                    CLICK HERE TO PRACTICE MORE TESTS
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AptitudetestCompo
